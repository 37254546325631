import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    // const filters = ['Page', 'File', 'Quiz', 'Assignment', 'Discussion', 'Announcement', 'Calendar', 'Submission']
    resources: {
      en: {
        translations: {
          "Projects": "Projects",
          "Project": "Project",
          "Customers": "Customers",
          "Customer": "Customer",
          "Dashboard": "Dashboard",
          "Edit": "Edit",
          "Email": "Email",
          "Password": "Password",
          "Login": "Login",
          "Logout": "Logout",
          "Active": "Active",
          "Archived": "Archived",
          "Archived-s": "Archived",
          "Name": "Name",
          "Description": "Description",
          "Domain": "Domain",
          "Update": "Update",
          "Cancel": "Cancel",
          "All": "All",
          "External": "External",
          "Build": "Build",
          "Version": "Version",
          "Date": "Date",
          "Release": "Release",
          "Releases": "Releases",
          "Of": "Of",
          "Tag": "Tag",
          "Tag message": "Tag message",
          "Release description": "Release description",
          "Release notes": "Release notes",
          "Deliverables": "Deliverables",
          "Assets": "Assets",
          "Documentation": "Documentation",
          "Path": "Path",
          "Failed to update": "Failed to update",
          "Failed to download file, try again later": "Failed to download file, try again later",
          "Act as": "Act as",
          "Customer login": "Customer login",
          "Visible": "Visible",
          "Login with google": "Login with google",
          "File": "File",
          "User": "User",
          "Downloads": "Downloads",
          "Invalid login, try again" : "Invalid login, try again",
          "Latest releases": "Latest releases"
        }
      },

      sv: {
        translations: {
          "Projects": "Projekt",
          "Project": "Projekt",
          "Customers": "Kunder",
          "Customer": "Kund",
          "Dashboard": "Översikt",
          "Edit": "Redigera",
          "Email": "Epost",
          "Password": "Lösenord",
          "Login": "Logga in",
          "Logout": "Logga ut",
          "Active": "Aktiva",
          "Archived": "Arkiverade",
          "Archived-s": "Arkiverad",
          "Name": "Namn",
          "Description": "Beskrivning",
          "Domain": "Domän",
          "Update": "Uppdatera",
          "Cancel": "Avbryt",
          "All": "Alla",
          "External": "Externa",
          "Build": "Bygge",
          "Version": "Version",
          "Date": "Datum",
          "Release": "Release",
          "Releases": "Releaser",
          "Of": "Av",
          "Tag": "Tagg",
          "Tag message": "Taggmeddelande",
          "Release description": "Releasebeskrivning",
          "Release notes": "Releaseanteckningar",
          "Deliverables": "Leverabler",
          "Assets": "Tillgångar",
          "Documentation": "Dokumentation",
          "Path": "Sökväg",
          "Failed to update": "Misslyckades att uppdatera",
          "Failed to download file, try again later": "Misslyckades att ladda ner fil, försök igen senare",
          "Act as": "Agera som",
          "Customer login": "Kundinlogg",
          "Visible": "Synlig",
          "Login with google": "Logga in med Google",
          "File": "Fil",
          "User": "Användare",
          "Downloads": "Nedladdningar",
          "Invalid login, try again" : "Felaktig inloggning, försök igen",
          "Latest releases" : "Senaste releaserna"
        }
      }
    },



    fallbackLng: "en",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;