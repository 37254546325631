
import gql from 'graphql-tag';

export const ACT_AS = gql`
  mutation actAs($groupId: Int!) {
    actAs(groupId: $groupId) {
      token
    }
  }
`;

export const CREATE_OR_UPDATE_PROJECT = gql`
    mutation CreateOrUpdateProject($projectId: Int!, $grinderUrl: String, $archived: Boolean, $visible: Boolean) {
      createOrUpdateProject(projectId: $projectId, grinderUrl: $grinderUrl, archived: $archived, visible: $visible) {
          id,
          projectId,
          grinderUrl,
          archived,
          visible
        }
    }
`;

export const CREATE_OR_UPDATE_GROUP = gql`
    mutation createOrUpdateGroup($groupId: Int!, $domain: String!, $archived: Boolean) {
      createOrUpdateGroup(groupId: $groupId, domain: $domain, archived: $archived) {
          id,
          domain,
          groupId,
          archived
        }
    }
`;

export const CREATE_OR_UPDATE_ARTIFACT = gql`
    mutation CreateOrUpdateArtifact($projectId: Int!, $buildId: Int! $grinderName: String!, $displayName: String, $visible: Boolean) {
      createOrUpdateArtifact(projectId: $projectId, buildId: $buildId, grinderName: $grinderName, displayName: $displayName, visible: $visible) {
        id,
        projectId,
        buildId,
        grinderName,
        displayName,
        visible
      }
    }
`;

export const LOGIN = gql`
  mutation login($emailOrUid: String!, $password: String!) {
    login(emailOrUid: $emailOrUid, password: $password) {
      token
    }
  }
`;