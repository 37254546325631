// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import FilterNone from "@material-ui/icons/FilterNone";
// core components/views for Admin layout
import DashboardPage from "./views/Dashboard/Dashboard";
import EditCustomer from "./views/EditCustomer/EditCustomer";
import EditProject from "./views/EditProject/EditProject";
import EditBuild from "./views/EditBuild/EditBuild";
import Customers from "./views/Customers/Customers";
import Projects from "./views/Projects/Projects";
import ProjectDetails from "./views/ProjectDetails/ProjectDetails";
import BuildDetails from "./views/BuildDetails/BuildDetails";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardPage
  },
  {
    path: "/projects",
    name: "Projects",
    icon: FilterNone,
    component: Projects
  },
  {
    path: "/projects/:projectid/edit",
    name: "Edit",
    icon: Person,
    component: EditProject,

    invisible: true
  },
  {
    path: "/projects/:projectid",
    name: "ProjectDetails",
    icon: Person,
    component: ProjectDetails,
    invisible: true
  },
  {
    path: "/projects/:projectid/releases/:buildid",
    name: "Edit",
    icon: Person,
    component: BuildDetails,
    invisible: true
  },
  {
    path: "/projects/:projectid/releases/:buildid/edit",
    name: "Edit",
    icon: Person,
    component: EditBuild,
    invisible: true
  },
  {
    path: "/customers",
    name: "Customers",
    icon: Person,
    component: Customers,
  },
  {
    path: "/customers/:customerid",
    name: "Edit",
    icon: Person,
    component: Projects,
    invisible: true
  },
  {
    path: "/customers/:customerid/projects/:projectid",
    name: "ProjectDetails",
    icon: Person,
    component: ProjectDetails,
    invisible: true
  },
  {
    path: "/customers/:customerid/projects/:projectid/edit",
    name: "Edit",
    icon: Person,
    component: EditProject,
    invisible: true
  },
  {
    path: "/customers/:customerid/projects/:projectid/releases/:buildid",
    name: "Edit",
    icon: Person,
    component: BuildDetails,
    invisible: true
  },
  {
    path: "/customers/:customerid/projects/:projectid/releases/:buildid/edit",
    name: "Edit",
    icon: Person,
    component: EditBuild,
    invisible: true
  },
  {
    path: "/customers/:customerid/edit",
    name: "Edit",
    icon: Person,
    component: EditCustomer,
    invisible: true
  },
];

export default dashboardRoutes;
