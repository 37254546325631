import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardAvatar from "../../components/Card/CardAvatar";
import CardBody from "../../components/Card/CardBody";
import CardFooter from "../../components/Card/CardFooter";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import {
  useParams,
  useRouteMatch,
  useHistory
} from "react-router-dom";
import { useQuery, useMutation} from '@apollo/react-hooks';
import { GET_GROUPS, GET_CURRENT_USER } from '../../graphql/queries'
import Snackbar from "../../components/Snackbar/Snackbar";
import { LOGIN } from "../../graphql/mutations";
import loginStyle from "../../assets/jss/styles/views/loginStyle";
import classNames from "classnames";
import bgImage from "../../assets/img/citylights-xxl.jpg";
import visiarc from "../../assets/img/visiarc.png";
import { useTranslation } from "react-i18next";
import {ReactComponent as NothingHere1}  from "../../assets/img/google.svg";


const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Source Sans Pro', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

//@ts-ignore
const useStyles = makeStyles(loginStyle);

const Login = (props:any) => {
  let win = window as any;
  let { customerid } = useParams<any>();
  const { t } = useTranslation();
  let history = useHistory();
  const classes = useStyles();
  const [ mutate ] = useMutation(LOGIN);
  const googleSignInError = win.error != "{{ERROR}}"
  const [showSnackbar, setShowSnackbar] = useState(googleSignInError);

  const [ customer, setCustomer ] = useState<any>();
  const [ timer, setTimer ] = useState(false);
  const [ inputRef, setInputRef ] = useState<any>();

  const {data, loading, error} = useQuery(GET_CURRENT_USER, {fetchPolicy: 'network-only'});

  if (!loading && !error) {
    history.push({
      pathname: `/` 
    })
  }

  const handleKeyDown = async (event:any) => {
    var ENTER_KEY = 13;
    switch( event.keyCode ) {
      case ENTER_KEY:
        await handleLogin();
        break;
      default: 
        break;
    }
  }

  useEffect(()=>{
    if (!loading && !timer) {
      setTimeout(()=> {
        setTimer(true);
      },100)
    }
  }, [loading])

  useEffect(() => {
    if (inputRef && timer && !customer) {
      setTimeout(()=> {
        inputRef.focus();
     }, 1000)
    }
  });

  const handleLogin = async () => {
    try {
      const { data } = await mutate({variables:{emailOrUid: customer.email, password: customer.password}})
      localStorage.setItem("jwt", data.login.token);
      history.push({
        pathname: `/` 
      })
    } catch (e) {
      setShowSnackbar(true);
    }
  }

  return (
    !loading ? <>
    <div style={{
      background: 'url('+bgImage+') no-repeat center',
      transition: 'height 1.5s',
      height:timer ? '140vh' : '100vh',
      width: '100vw',
      display: 'flex', 
      justifyContent: 'center', 
      alignItems:'center', 
      overflow: 'hidden',
      position:'absolute'
    }}> </div>
    <div onKeyDown={handleKeyDown} style={{
        height:'100vh',
        display: 'flex', 
        justifyContent: 'center', 
        alignItems:'center', 
        overflow: 'hidden'
      }}>
      <Snackbar
          place="tc"
          color="danger"
          icon={ErrorOutline}
          message={t("Invalid login, try again")}
          open={showSnackbar}
          closeNotification={() => setShowSnackbar(false)}
          close
        />
      <div style={!timer ? {...loginStyle.card} : {...loginStyle.card, transform: 'translateY(0vh)'}}>
            <div style={{width: '100%', display: 'flex', justifyContent: 'center', transition: 'transform 1s ease 0.3s', transform: timer ? 'translateY(0vh)' : 'translateY(10vh)', zIndex: 100 }}>
              <CardHeader login color={"success"}>
                <img style={{width: '50%', minWidth: 150}} src={visiarc} />
                <h4 style={{margin:'0.5rem', fontSize: '1.5rem', font: 'small-caps', letterSpacing: 5}}>{t("Customer login")}</h4>
              </CardHeader>
            </div>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText={t("Email")}
                    id="email-address"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      autoComplete:'email',
                      inputRef:(input:any) => { setInputRef(input) },
                      value: customer? customer.email : "",
                      onChange: (event:any) => {
                        setCustomer({ ...customer, email:event.target.value })
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText={t("Password")}
                    id="password"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      autoComplete:'password',
                      value: customer ? customer.password : "",
                      type: "password",
                      onChange: (event:any) => {
                        setCustomer({ ...customer, password:event.target.value })
                      }
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <div style={{display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems:'center', padding: '1rem'}}>
                <Button disabled={!customer || !customer.email || !customer.password} onClick={async ()=>{
                  await handleLogin();
                }} color={"rose"}>{t("Login")}</Button>

                <Button color={"white"} onClick={()=>window.location.replace(win.googleLoginUrl)} >
                  <NothingHere1 />
                  <span style={{width: '0.8rem'}}/>
                  {t("Login with google").toUpperCase()}
                </Button>
            </div>
      </div>
    </div>
    </> : <></>
  );
}

export default Login