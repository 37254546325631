import {
  blackColor,
  whiteColor,
  hexToRgb
} from "../../styles";

import cardStyle from "../components/cardStyle"
import cardHeaderStyle from "../components/cardHeaderStyle";

const loginStyle = {
  card: {
    ...cardStyle.card,
    width: '50%',
    marginBottom: '3rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'linear-gradient(60deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9))',
    padding: '2rem',
    paddingBottom: '3rem',
    maxWidth: '50rem',
    transition: 'transform 0.5s ease 0.4s',
    transform: 'translateY(80vh)'
  }
};

export default loginStyle;