import {
  blackColor,
  whiteColor,
  hexToRgb
} from "../../styles";
import { DetailedHTMLProps } from "react";
import { CSSProperties } from "@material-ui/styles";

const cardStyle = {
  card: {
    border: "0",
    marginBottom: "30px",
    marginTop: "30px",
    borderRadius: "6px",
    color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
    background: whiteColor,
    width: "100%",
    boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
    position: "relative" as any,
    display: "flex",
    flexDirection: "column" as any,
    minWidth: "0",
    wordWrap: "break-word" as any,
    fontSize: ".875rem"
  },
  cardPlain: {
    background: "transparent",
    boxShadow: "none"
  },
  cardProfile: {
    marginTop: "30px",
    textAlign: "center"
  },
  cardChart: {
    marginBottom: 0
  },
  cardLogin: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: '0 4px 20px 0 rgba(040, 040, 040,0.3), 0 7px 10px -5px rgba(040, 040, 040,0.3) !important'
  },
};

export default cardStyle;
