import React, { useState } from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import AllInbox from "@material-ui/icons/AllInbox";
import Visibility from "@material-ui/icons/Visibility";
import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import Table from "../../components/Table/Table";
import NavBar from "../../components/Navbars/Navbar";

import moment from 'moment-timezone';
import 'moment/locale/sv'

import { builds } from "../../variables/general";
import {
  useParams,
  useRouteMatch,
  useHistory
} from "react-router-dom";

import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardAvatar from "../../components/Card/CardAvatar";
import CardBody from "../../components/Card/CardBody";
import CardFooter from "../../components/Card/CardFooter";
import Button from "../../components/CustomButtons/Button";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import styles from "../../assets/jss/styles/views/dashboardStyle";
import { useQuery } from "@apollo/react-hooks";
import { GET_PROJECTS, GET_CUSTOMER_PROJECTS, GET_RELEASES, GET_CURRENT_USER } from "../../graphql/queries";
import { UserRole } from "../../helpers";
import { useTranslation } from "react-i18next";

//@ts-ignore
const useStyles = makeStyles(styles);

const ProjectDetails = () => {
  let { projectid, customerid } = useParams<any>();
  const { t } = useTranslation();
  let history = useHistory();
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:1120px)');

  const [project, setProject] = useState<any>();
  const [releases, setReleases] = useState<any>();

  const { data:currentUserData } = useQuery(GET_CURRENT_USER);
  const { data:ourProjectsData } = useQuery(GET_PROJECTS);
  const { data:customersProjectsData } = useQuery(GET_CUSTOMER_PROJECTS,{ variables: {groupId: parseInt(customerid ? customerid : "")}, fetchPolicy: 'network-only'});
  const { data:releasesData } = useQuery(GET_RELEASES,{ variables: {projectId: parseInt(projectid ? projectid : "")}, fetchPolicy: 'network-only'});

  if (releasesData && !releases) {
    const releases = releasesData.releases.map((release:any) => {
      return {...release, date: moment.tz(release.date, "Europe/Stockholm").format('YYYY-MM-DD HH:mm')}
    });
    setReleases(releases);
  }
  if (!project) {
    if (customerid && customersProjectsData) {
      const p = customersProjectsData.projects.filter((project:any) => {
        if (project.id == projectid) {
          return true
        }
        return false;
      })[0];
      setProject(p)
    } else if (!customerid && ourProjectsData) {
      const p = ourProjectsData.projects.filter((project:any) => {
        if (project.id == projectid) {
          return true
        }
        return false;
      })[0];
      setProject(p)
    }
  }

  const buildTableHeaders = [{key:"build", name:t("Build")}, {key:"tag", name:t("Version")}, {key:"date", name:t("Date")}]
  
  const getProjectInfo = () => {
    return <GridItem xs={12} sm={12} md={12}>
    <Card>
      <CardBody>
      <div style={{display:'flex', flexDirection: matches ? 'row' : 'column', justifyContent: 'space-between', alignItems: 'center'}}>
        {project.avatar_url &&
        <a onClick={e => e.preventDefault()}>
          <img src={project.avatar_url+''} style={{height: 50}} />
        </a>}
        <h4 className={classes.cardTitle}>{project.name}</h4>
        <p className={classes.description} style={{maxWidth: matches ? '50%' : '100%'}}>
          {project.description}
        </p>
        {currentUserData.currentUser.role === UserRole.Admin && <Button color="primary" round onClick={()=>{
          history.push({
            pathname: customerid ? `/customers/${customerid}/projects/${projectid}/edit` : `/projects/${projectid}/edit`
          });
        }}>
          {t("Edit")}
        </Button>}
        </div>
      </CardBody>
    </Card>
  </GridItem>
  }

  const getTabs = () => {
    let tabs = [];
    if (currentUserData.currentUser.role === UserRole.Admin) {
      tabs.push({
        tabName: t("All"),
        tabIcon: AllInbox,
        tabContent: (
          <Table
            tableHeaders={buildTableHeaders}
            tableHeaderColor="danger"
            tableData={releases ? releases : []}
            editable={customerid && project && project.grinderUrl ? true : false}
            type={"build"}
          />
        )
      })
      tabs.push({
        tabName: t("External"),
        tabIcon: Visibility,
        disabled: !releases || !releases.filter((release:any)=>{return release.tag.includes("ext")}).length,
        tabContent: (
          <Table
            tableHeaders={buildTableHeaders}
            tableHeaderColor="danger"
            tableData={releases ? releases.filter((release:any)=>{return release.tag.includes("ext")}) : []}
            editable={customerid && project && project.grinderUrl ? true : false}
            type={"build"}
          />
        )
      });
    } else {
      tabs.push({
        tabName: "",
        tabIcon: null,
        tabContent: (
          <Table
            tableHeaders={buildTableHeaders}
            tableHeaderColor="danger"
            tableData={releases ? releases.filter((release:any)=>{return release.tag.includes("ext")}) : []}
            editable={customerid && project && project.grinderUrl ? true : false}
            type={"build"}
          />
        )
      });
    }
    return tabs;
  }

  return (
    <div>
      <NavBar />
      {project && getProjectInfo()}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTabs
            title={t("Releases")}
            headerColor="danger"
            tabs={getTabs()}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default ProjectDetails
