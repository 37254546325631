import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import ApolloClient from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { ApolloProvider } from "@apollo/react-hooks";
import { ApolloLink } from "apollo-link";
import { onError } from 'apollo-link-error';

import {
  createMuiTheme
} from '@material-ui/core';

// core components
import MainLayout from "./layouts/MainLayout";
import Login from "./views/Login/Login";

import "./assets/css/styles.css";
import { ThemeProvider } from "@material-ui/styles";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Source Sans Pro', 'Arial', 'Helvetica', 'sans-serif'
    ].join(','),
  },
});

let win = window as any;
if (win.jwt != "{{JWT}}" && win.jwt != localStorage.getItem("jwt")) {
  localStorage.setItem("jwt", win.jwt)
}

const hist = createBrowserHistory();

const cache = new InMemoryCache();

const middlewareLink = setContext(() => {
  // Send jwt with every request
  return {headers: {
    "Authorization": localStorage.getItem("jwt") ? "Bearer " + localStorage.getItem("jwt") : ''
  }}
});

const htttpLink = new HttpLink({ uri: `/graphql` });


const httpLinkWithMiddleware = middlewareLink.concat(htttpLink);

const client = new ApolloClient({
  cache,
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        for (let err of graphQLErrors) {
          if (err.extensions && err.extensions.code === 'UNAUTHENTICATED') {
            console.log("UNAUTHENTICATED")
            hist.push('/login');
          }
        }
      }
      if (networkError) {
        console.log("network error")
      }
    }),
    httpLinkWithMiddleware
  ])
});
  
  ReactDOM.render(
    <I18nextProvider i18n={i18n}>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <Router history={hist}>
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/" component={MainLayout} />
            </Switch>
          </Router>
        </ThemeProvider>
      </ApolloProvider>
    </I18nextProvider>,
    document.getElementById("root")
  );
