import React, { useEffect, useState } from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
// core components
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardIcon from "../../components/Card/CardIcon";
import CardBody from "../../components/Card/CardBody";
import Table from "../../components/Table/Table";

import { projectsChart, customersChart } from "../../variables/charts";

import styles from "../../assets/jss/styles/views/dashboardStyle";
import { Typography } from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import { GET_PROJECTS, GET_GROUPS, GET_CURRENT_USER, GET_DOWNLOADS, GET_RELEASES } from "../../graphql/queries";
import { UserRole } from "../../helpers";
import { useHistory, useRouteMatch } from "react-router-dom";
import NavBar from "../../components/Navbars/Navbar";
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import 'moment/locale/sv'
  
//@ts-ignore
const useStyles = makeStyles(styles);

const Dashboard = () => {
  const classes = useStyles();
  let history = useHistory();
  const { t } = useTranslation();
  
  let projects = "-"
  let customers = "-"
  const [downloads, setDownloads] = useState<any>();
  const [releases, setReleases] = useState<any>([]);
  const { data:ourProjectsData } = useQuery(GET_PROJECTS);
  const { data:ourCustomers } = useQuery(GET_GROUPS);
  const { data:currentUserData } = useQuery(GET_CURRENT_USER);
  const { data:downloadsData } = useQuery(GET_DOWNLOADS, {fetchPolicy: 'network-only'});
  const { data:releasesData, refetch:refetchReleases } = useQuery(GET_RELEASES, {fetchPolicy: 'network-only'});

  if (ourProjectsData) {
    projects = ourProjectsData.projects.length;
  }

  useEffect(() => {
    if (currentUserData.currentUser.role === UserRole.Customer && ourProjectsData && !releases.length) {
      const fetchDataAsync = async () => {
        let temp = []
        for(let i = 0; i < ourProjectsData.projects.length; i++) {
          let res:any = await refetchReleases({projectId: ourProjectsData.projects[i].id, limit: 10});
          res = res.data.releases.map((release:any) => {
            return {...release, project: ourProjectsData.projects[i].name, projectId: ourProjectsData.projects[i].id, date: moment.tz(release.date, "Europe/Stockholm").format('YYYY-MM-DD HH:mm')}
          });
          temp.push(...res)
        }
        temp = temp.sort((a:any, b:any) => {
          if (moment(a.date) > moment(b.date)) {
            return -1;
          } else if (moment(b.date) > moment(a.date)) {
            return 1;
          } else {
            return 0;
          }
        })
        setReleases(temp.slice(0,10))
      }
      fetchDataAsync()
    }
   }, [ourProjectsData]);

  if (ourCustomers) {
    customers = ourCustomers.groups.length;
  }

  if (downloadsData && !downloads) {
    setDownloads(downloadsData.downloads);
  }

  let projectTableHeaders = [
    {key:"displayName", name:t("File")},
    {key:"projectName", name:t("Project")},
    {key:"buildId", name:t("Build")+"/"+t("Tag")},
    {key:"email", name:t("User")}, 
    {key:"date", name:t("Date")},
  ]

  const buildTableHeaders = [
    {key:"project", name:t("Project")},
    {key:"tag", name:t("Version")}, 
    {key:"date", name:t("Date")}
  ]

  return (
    <>
    <NavBar />
    <div style={{marginTop: 30}}>
      <GridContainer>
      <span style={{width:'50%', cursor: 'pointer'}} onClick={()=>{
          history.push({
            pathname: '/projects' 
          })
        }}><GridItem xs={12} sm={12} md={12}>
          <Card chart>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Icon>content_copy</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>{t("Projects")}</p>
              <h3 className={classes.cardTitle}>{projects}</h3>
            </CardHeader>
            <CardBody />
          </Card>
        </GridItem></span>
        {currentUserData.currentUser.role === UserRole.Admin && <span style={{width:'50%', cursor: 'pointer'}} onClick={()=>{
          history.push({
            pathname: '/customers' 
          })
        }}><GridItem xs={12} sm={12} md={12}>
          <Card chart>
            <CardHeader color="rose" stats icon>
              <CardIcon color="rose">
                <Person />
              </CardIcon>
              <p className={classes.cardCategory}>{t("Customers")}</p>
              <h3 className={classes.cardTitle}>{customers}</h3>
            </CardHeader>
            <CardBody />
          </Card>
        </GridItem></span>}
      </GridContainer>
      {currentUserData.currentUser.role === UserRole.Admin && <Card>
        <div style={{
            color: 'rgb(195 195 195)', 
            fontVariant: 'all-small-caps', 
            letterSpacing: 1, 
            margin: '2rem 0 0 2rem',
            fontSize: 20,
            fontWeight: 600
          }}>
            {t("Downloads")}
        </div>
        <div style={{padding: '0 1rem 1rem 1rem'}}>
          <Table
            tableHeaders={projectTableHeaders}
            tableHeaderColor="primary"
            tableData={downloads ? downloads : []}
            editable={false}
            type={"download"}
          />
        </div>
      </Card>}
      {currentUserData.currentUser.role === UserRole.Customer && <Card>
        <div style={{
            color: 'rgb(195 195 195)', 
            fontVariant: 'all-small-caps', 
            letterSpacing: 1, 
            margin: '2rem 0 0 2rem',
            fontSize: 20,
            fontWeight: 600
          }}>
            {t("Latest releases")}
        </div>
        <div style={{padding: '0 1rem 1rem 1rem'}}>
        <Table
          tableHeaders={buildTableHeaders}
          tableHeaderColor="primary"
          tableData={releases ? releases.filter((release:any)=>{return release.tag.includes("ext")}) : []}
          editable={false}
          type={"dashboard-releases"}
        />
        </div>
      </Card>}
    </div>
    </>
  );
}

export default Dashboard
