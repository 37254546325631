import React, { useState } from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import Done from "@material-ui/icons/Done";
import Archive from "@material-ui/icons/Archive";
import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import Table from "../../components/Table/Table";
import NavBar from "../../components/Navbars/Navbar";

import styles from "../../assets/jss/styles/views/dashboardStyle";

import { useQuery} from '@apollo/react-hooks';
import { GET_GROUPS } from '../../graphql/queries'
import { useTranslation } from "react-i18next";

//@ts-ignore
const useStyles = makeStyles(styles);

const Customers = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const customerTableHeaders = [{key:"avatar", name:""}, {key:"name", name:t("Name")}, {key:"description", name:t("Description")}]
  const [customers, setCustomers] = useState([]);
  const { data, error, loading } = useQuery(GET_GROUPS, {fetchPolicy: 'network-only'});

  if (data && !customers.length) {
    setCustomers(data.groups);
  }

  return (
    <div>
      <NavBar />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTabs
            title={t("Customers")}
            headerColor="rose"
            tabs={[
              {
                tabName: t("Active"),
                tabIcon: Done,
                tabContent: (
                  <Table
                    tableHeaders={customerTableHeaders}
                    tableHeaderColor="rose"
                    tableData={customers.filter((customer:any)=>{return !customer.archived})}
                    editable={true}
                    type={"customer"}
                  />
                )
              },
              {
                tabName: t("Archived"),
                tabIcon: Archive,
                disabled: !customers || !customers.filter((customer:any)=>{return customer.archived}).length,
                tabContent: (
                  <Table
                    tableHeaders={customerTableHeaders}
                    tableHeaderColor="rose"
                    tableData={customers.filter((customer:any)=>{return customer.archived})}
                    editable={true}
                    type={"customer"}
                  />
                )
              }
            ]}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default Customers
