import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const styles = {
  grid: {
    padding: "0 15px !important"
  }
};

const useStyles = makeStyles(styles);

interface GridItemProps {
  children: any,
  xs: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
  sm: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
  md: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
};

const GridItem = (props: GridItemProps) => {
  const classes = useStyles();
  const { children, ...rest } = props;
  return (
    <Grid item xs={props.xs} sm={props.sm} md={props.md} className={classes.grid}>
      {children}
    </Grid>
  );
}

export default GridItem

