import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes, { any } from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import styles from "../../assets/jss/styles/components/cardStyle";

//@ts-ignore
const useStyles = makeStyles(styles);

interface CardProps {
  className?: any,
  plain?: boolean,
  profile?: boolean,
  chart?: boolean,
  login?: boolean,
  children?: any
};

const Card = (props: CardProps) => {
  const classes = useStyles();
  const { className, children, plain, profile, chart, login, ...rest } = props;
  const cardClasses = classNames({
    [classes.card]: true,
    [classes.cardPlain]: plain,
    [classes.cardProfile]: profile,
    [classes.cardChart]: chart,
    [classes.cardLogin]: login,
    [className]: className !== undefined
  });
  return (
    <div className={cardClasses} {...rest}>
      {children}
    </div>
  );
}

export default Card;
