import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/Edit";
import Person from "@material-ui/icons/Person";
import Visibility from "@material-ui/icons/Visibility";
// core components
import styles from "../../assets/jss/styles/components/tableStyle";
import {
  useParams,
  useRouteMatch,
  useHistory,
  useLocation
} from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { GET_RELEASE_INFO, GET_CURRENT_USER, GET_RELEASES } from "../../graphql/queries";
import { TableFooter, TablePagination } from "@material-ui/core";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import { UserRole } from "../../helpers";
import { useTranslation } from "react-i18next";
import { ACT_AS, LOGIN } from "../../graphql/mutations";

const ROWS_PER_PAGE = 10;

//@ts-ignore
const useStyles = makeStyles(styles);

interface CustomTableProps {
  tableHeaderColor:
    | "warning"
    | "primary"
    | "danger"
    | "success"
    | "info"
    | "rose"
    | "gray";
  tableHeaders: any[];
  tableData: any[];
  editable: boolean;
  type: any;
}

const CustomTable = (props: CustomTableProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { tableHeaders, tableData, tableHeaderColor, editable, type } = props;
  let { projectid, customerid, buildid } = useParams<any>();
  let history = useHistory();
  const [page, setPage] = useState<any>(0);
  const [sortedData, setSortedData] = useState<any>([]);
  const [ mutate ] = useMutation(ACT_AS);

  const [release, setRelease] = useState<any>();
  const { data:releasesData } = useQuery(GET_RELEASES,{ variables: {projectId: parseInt(projectid ? projectid : "")}});

  if (releasesData && !release) {
    const r = releasesData.releases.find((release:any) => {
      return buildid == release.build || release.tag.includes(buildid)
    });
    if (r != release) {
      setRelease(r);
    }
  }

  const { data: buildData, error, loading } = useQuery(GET_RELEASE_INFO,{ variables: {
    projectId: projectid ? parseInt(projectid) : "",
    build: release ? parseInt(release.build) : -1,
    tag: release ? release.tag : "",
  }, fetchPolicy: 'network-only'});

  const { data:currentUserData } = useQuery(GET_CURRENT_USER);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    let sortedTableData:any = tableData.sort((a:any, b:any) => {
      if (a.visible) {
        return -1;
      } else if (b.visible) {
        return 1;
      } else {
        return 0
      }
    })
    setSortedData(sortedTableData)
  }, [tableData])

  const slicedData = sortedData.slice(page*ROWS_PER_PAGE, (page+1)*ROWS_PER_PAGE);

  const handleEditClick = (event: any, task: any) => {
    event.stopPropagation();
    let toUrl = ""; 
    let tag = task.tag;
    if (currentUserData.currentUser.role !== UserRole.Admin && tag) {
      tag = tag.replace("ext-", "")
    }
    if (customerid && type === "build") {
      toUrl = `/customers/${customerid}/projects/${projectid}/releases/${tag ? tag : task.build}/edit`;
    } else if (type === "build") {
      toUrl = `/projects/${projectid}/releases/${tag ? tag : task.build}/edit`;
    } else if (customerid && type === "project") {
      toUrl = `/customers/${customerid}/projects/${task.id}/edit`;
    } else if (type === "project") {
      toUrl = `/projects/${task.id}/edit`;
    } else if (type === "customer") {
      toUrl = `/customers/${task.id}/edit`;
    }

    history.push({
      pathname: toUrl 
    })
  };

  const handleActAsClick = async (event: any, task: any) => {
    event.stopPropagation();
    try {
      const { data } = await mutate({variables:{groupId: task.id}})
      if (data) {
        localStorage.setItem("jwt", data.actAs.token);
        window.location.replace(`/`);
      }
    } catch (e) {
      console.log("Failed to act as user", e)
    }
  };


  const handleClick = (event:any, task:any) => {
    let toUrl = "";
    let state = {};
    let tag = task.tag;
    if (currentUserData.currentUser.role !== UserRole.Admin && tag) {
      tag = tag.replace("ext-", "")
    }
    if (buildData && buildData.releaseInfo && buildData.releaseInfo.sha && type !== "build") {
      const jwt = localStorage.getItem('jwt');
      window.location.replace(process.env.NODE_ENV === 'production' ? 
      `https://${process.env.REACT_APP_DOMAIN}/file/${task.name}?build=${release.build}&path=${task.path}&ref=${buildData.releaseInfo.sha}&projectId=${projectid}&jwt=${jwt}&tag=${buildid}` :
      `http://localhost:3001/file/${task.name}?build=${release.build}&path=${task.path}&ref=${buildData.releaseInfo.sha}&projectId=${projectid}&jwt=${jwt}&tag=${buildid}` 
      );
    } else if (projectid && customerid) {
      toUrl = tag ? `/customers/${customerid}/projects/${projectid}/releases/${tag}` : 
      `/customers/${customerid}/projects/${projectid}/releases/${task.build}`;
      state = {buildName: task.build, tagName: task.tag};
    } else if (projectid) {
      toUrl = tag ? `/projects/${projectid}/releases/${tag}` : 
      `/projects/${projectid}/releases/${task.build}`;
      state = {buildName: task.build, tagName: task.tag};
    } else if (customerid) {
      toUrl = `/customers/${customerid}/projects/${task.id}`;
    } else if (type === "project") {
      toUrl = `/projects/${task.id}`;
    } else if (type === "customer") {
      toUrl = `/customers/${task.id}`;
    } else if (type === "download") {
      toUrl = `/customers/${task.groupId}/projects/${task.projectId}/releases/${task.buildId}`;
    } else if (type === "dashboard-releases") {
      toUrl = `/projects/${task.projectId}/releases/${task.tag}`;
    }
    if (toUrl) {
      history.push({
        pathname: toUrl,
        state: state
      })
    }
  }

  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
          <TableRow className={classes.tableHeadRow}>
            {tableHeaders.map(header => {
              return (
                <TableCell
                  className={classes.tableCell + " " + classes.tableHeadCell}
                  key={header.key}
                >
                  {header.name}
                </TableCell>
              );
            })}
            <TableCell
              className={classes.tableCell + " " + classes.tableHeadCell}
              key={"edit"}
            ></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {slicedData.map((prop:any, key:any) => {
            let showEditRelease = true;
            if (prop["build"] || prop["tag"]) {
              if (!prop["tag"].includes("ext-")) {
                showEditRelease = false;
              } 
            }
            return (
              <TableRow key={key} className={classes.tableBodyRow} hover onClick={event => handleClick(event, prop)}>
                {tableHeaders.map((header: any) => {
                  let value:any = "";
                  if (prop[header.key]) {
                    value = prop[header.key];
                  }
                  // For "visiable" property
                  if (typeof value === "boolean") {
                    value = <Visibility />
                  }
                  return (
                    <TableCell className={classes.tableCell} key={header.key}>
                      {header.key === "avatar" && prop["avatar_url"] && 
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                          <div className="table-icon" style={{
                            backgroundImage:`url(${prop["avatar_url"]})`, 
                          }} />
                        </div>
                      }
                      {currentUserData.currentUser.role !== UserRole.Admin ? 
                      value ? value.toString().replace("ext-", "") : "" :
                      value}
                    </TableCell>
                  );
                })}
                <TableCell className={classes.tableActions}>
                {currentUserData.currentUser.role === UserRole.Admin && type === "customer" && prop.actAs && <Tooltip
                    id="tooltip-top"
                    title={t("Act as")}
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                     <IconButton
                      onClick={event => handleActAsClick(event, prop)}
                      aria-label={t("Act as")}
                      className={classes.tableActionButton}
                    >
                      <Person
                        className={
                          classes.tableActionButtonIcon + " " + classes.edit
                        }
                      />
                    </IconButton>
                  </Tooltip>}
                  {currentUserData.currentUser.role === UserRole.Admin && editable && showEditRelease && <Tooltip
                    id="tooltip-top"
                    title={t("Edit")}
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <IconButton
                      onClick={event => handleEditClick(event, prop)}
                      aria-label={t("Edit")}
                      className={classes.tableActionButton}
                    >
                      <Edit
                        className={
                          classes.tableActionButtonIcon + " " + classes.edit
                        }
                      />
                    </IconButton>
                  </Tooltip>}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        style={{border: 'none', float:'right'}}
        labelRowsPerPage={""}
        rowsPerPageOptions={[]}
        count={tableData.length}
        rowsPerPage={ROWS_PER_PAGE}
        page={page}
        onChangePage={handleChangePage}
        labelDisplayedRows={({ from, to, count })=>{
          return `${from} - ${to} ${t("Of").toLowerCase()} ${count}`;
        }}
      />
    </div>
  );
};

export default CustomTable;
