import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardAvatar from "../../components/Card/CardAvatar";
import CardBody from "../../components/Card/CardBody";
import CardFooter from "../../components/Card/CardFooter";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import NavBar from "../../components/Navbars/Navbar";
import {
  useParams,
  useRouteMatch,
  useHistory
} from "react-router-dom";
import { GET_PROJECTS, GET_CUSTOMER_PROJECTS } from "../../graphql/queries";
import { useQuery, useMutation} from '@apollo/react-hooks';
import { CREATE_OR_UPDATE_PROJECT } from "../../graphql/mutations";
import Snackbar from "../../components/Snackbar/Snackbar";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import { setContext } from "apollo-link-context";
import { useTranslation } from "react-i18next";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Source Sans Pro', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

//@ts-ignore
const useStyles = makeStyles(styles);

const Edit = (props:any) => {
  let { customerid, projectid } = useParams<any>();
  let history = useHistory();
  const classes = useStyles()
  const { t } = useTranslation();

  const [project, setProject] = useState<any>();

  const { data:ourProjectsData } = useQuery(GET_PROJECTS, {fetchPolicy: 'network-only'});
  const { data:customersProjectsData } = useQuery(GET_CUSTOMER_PROJECTS,{ variables: {groupId: parseInt(customerid ? customerid : "")}, fetchPolicy: 'network-only'});
  const [ mutate ] = useMutation(CREATE_OR_UPDATE_PROJECT);

  // const [grinderUrl, setGrinderUrl] = useState(project ? project.grinderUrl : "")
  // const [archived, setArchived] = useState(project ? project.archived : false)
  const [showSnackbar, setShowSnackbar] = useState(false);

  if (!project) {
    if (customerid && customersProjectsData) {
      const p = customersProjectsData.projects.filter((project:any) => {
        if (project.id == projectid) {
          return true
        }
        return false;
      })[0];
      setProject(p);
    } else if (!customerid && ourProjectsData) {
      const p = ourProjectsData.projects.filter((project:any) => {
        if (project.id == projectid) {
          return true
        }
        return false;
      })[0];
      setProject(p);
    }
  }

  return (
    <div>
      <Snackbar
        place="tc"
        color="danger"
        icon={ErrorOutline}
        message={t("Failed to update") + " " + t("Project").toLowerCase()}
        open={showSnackbar}
        closeNotification={() => setShowSnackbar(false)}
        close
      />
      <NavBar />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color={"info"}>
              <h4 className={classes.cardTitleWhite}>{t("Edit")+" "+t("Project").toLowerCase()}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    info
                    labelText={t("Name")}
                    id="name"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: project ? project.name : ""
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    info
                    labelText={t("Description")}
                    id="description"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: project ? project.description : ""
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    info
                    labelText="Git url"
                    id="git-url"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: project ? project.web_url : ""
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    info
                    labelText="Grinder url"
                    id="grinder-url"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: project ? project.grinderUrl : "",
                      onChange: (event:any) => {
                        if (!project) return false;
                        setProject({ ...project, grinderUrl:event.target.value })
                      }
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <div style={{height: '2rem'}} />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <FormGroup row>
                    {<FormControlLabel checked={project && project.visible ? true : false} control={<Switch onChange={(event:any)=>{
                      if (!project) return;
                      setProject({ ...project, visible: !project.visible})
                    }} color="primary"/>} label={t("Visible")} />}
                    <FormControlLabel checked={project && project.archived ? true : false} control={<Switch onChange={(event:any)=>{
                      if (!project) return;
                      setProject({ ...project, archived: !project.archived})
                    }} color="primary"/>} label={t("Archived-s")} />
                  </FormGroup>
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <div>
                <Button onClick={async ()=>{
                  try {
                    let data = await mutate({variables:{projectId: parseInt(projectid ? projectid : "-1"), grinderUrl: project.grinderUrl, archived: project.archived, visible: project.visible}})
                    if (data) {
                      history.goBack();
                    } else {
                      throw "failed to save"
                    }
                  } catch (e) {
                    setShowSnackbar(true);
                  }
                }} color={"info"}>{t("Update")+" "+t("Project")}</Button>
                <Button onClick={async ()=>{
                    history.goBack();
                  }} color={"transparent"}>{t("Cancel")}</Button>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default Edit