import React, {useState, useEffect} from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "../components/Navbars/Navbar";
import Sidebar from "../components/Sidebar/Sidebar";

import routes from "../routes";

import styles from "../assets/jss/styles/layouts/adminStyle";

import bgImage from "../assets/img/sidebar-2.jpg";
import logo from "../assets/img/logo.png";
import { useQuery } from "@apollo/react-hooks";
import { GET_CURRENT_USER } from "../graphql/queries";
import { UserRole } from "../helpers";

import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
import { useTranslation } from "react-i18next";

let perfectScroll:any;

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      return (
        <Route
          exact
          path={prop.path}
          component={prop.component}
          key={key}
        />
      );
    })}
    <Redirect from="/" to="/dashboard" />
  </Switch>
);


//@ts-ignore
const useStyles = makeStyles(styles);

const MainLayout = ({ ...rest }) => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    var lang = navigator.languages;
    let sv = false;
    if (lang && lang.length) {
      lang.forEach(l => {
        if (l.includes("sv")) {
          sv = true;
        }
      });
    }
    i18n.changeLanguage(sv ? "sv" : "en");
  }, []);

  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel:any = React.createRef();
  // states and functions
  const [image, setImage] = useState(bgImage);
  const [color, setColor] = useState("orange");
  const [fixedClasses, setFixedClasses] = useState("dropdown show");
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleImageClick = (image:string) => {
    setImage(image);
  };
  const handleColorClick = (color:string) => {
    setColor(color);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    if (window.innerWidth < 960) {
      setMobileOpen(!mobileOpen);
    }
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  const { data:currentUserData, loading } = useQuery(GET_CURRENT_USER);

  if (loading) {
    return <></>
  }

  // // initialize and destroy the PerfectScrollbar plugin
  // useEffect(() => {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     perfectScroll = new PerfectScrollbar(mainPanel.current, {
  //       suppressScrollX: true,
  //       suppressScrollY: false
  //     });
  //     document.body.style.overflow = "hidden";
  //   }
  //   window.addEventListener("resize", resizeFunction);
  //   // Specify how to clean up after this effect:
  //    const cleanup = () => {
  //     if (navigator.platform.indexOf("Win") > -1) {
  //       perfectScroll.destroy();
  //     }
  //     window.removeEventListener("resize", resizeFunction);
  //   };
  //   return cleanup
  // }, [mainPanel]);

  let filteredRoutes = routes.filter((route)=>{
    if (route.path.includes("customers") && currentUserData.currentUser.role === UserRole.Customer) {
      return false;
    }
    return true;
  })

  return (
    <div>
      <Sidebar
        routes={filteredRoutes}
        logoText={currentUserData.currentUser.name}
        logo={currentUserData.currentUser.avatar_url}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        {...rest}
      />
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerToggle}
      >
        <Menu />
      </IconButton>
      <div className={classes.mainPanel} ref={mainPanel}>
        <div className={classes.content}>
          <div className={classes.container}>{switchRoutes}</div>
        </div>
      </div>
    </div>
  );
}

export default MainLayout
