/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
// core components
import {
  useHistory
} from "react-router-dom";

import styles from "../../assets/jss/styles/components/sidebarStyle";
import Button from "../../components/CustomButtons/Button";
import { useApolloClient } from "@apollo/react-hooks";
import { useTranslation } from 'react-i18next';

//@ts-ignore
const useStyles = makeStyles(styles);

interface SidebarProps {
  rtlActive?: boolean,
  handleDrawerToggle?: any,
  bgColor?: "purple" | "blue" | "green" | "orange" | "red",
  logo?: string,
  image?: string,
  logoText?: string,
  routes: any[],
  open?: boolean,
  color?: string
};

const Sidebar = (props:SidebarProps) => {
  let history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName: string) => {
    if (routeName === "/projects" && window.location.href.indexOf("/customers") > -1) {
      return false;
    }
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }
  const { color, logo, image, logoText, routes } = props;
  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {

      let linkColor = '#fdb45e';
      if (prop.path.includes("projects")) {
        linkColor = '#5ec2fd'
      } else if (prop.path.includes("customers")) {
        linkColor = '#f3664d'
      }
        console.log(prop.path)
        var activePro = " ";
        var listItemClasses;
        if (prop.invisible) return null;
        listItemClasses = classNames({
          [" " + classes[color]]: activeRoute(prop.path)
        });
        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]: activeRoute(prop.path)
        });
        return (
          <NavLink
            to={prop.path}
            className={activePro + classes.item}
            activeClassName="active"
            key={key}
            onClick={()=>{
              props.handleDrawerToggle();
            }}
          >
            <ListItem className={classes.itemLink + listItemClasses}>
              {typeof prop.icon === "string" ? (
                <Icon 
                  className={classNames(classes.itemIcon, whiteFontClasses, {
                    [classes.itemIconRTL]: props.rtlActive
                  })}
                >
                  {prop.icon}
                </Icon>
              ) : (
                <prop.icon style={{fill: activeRoute(prop.path) ? linkColor : 'inherit'}}
                  className={classNames(classes.itemIcon, whiteFontClasses, {
                    [classes.itemIconRTL]: props.rtlActive
                  })}
                />
              )}
              <ListItemText
                primary={t(prop.name)}
                className={classNames(classes.itemText, whiteFontClasses, {
                  [classes.itemTextRTL]: props.rtlActive
                })}
                disableTypography={true}
              />
            </ListItem>
          </NavLink>
        );
      })}
    </List>
  );
  var brand = (
    <div className={classes.logo} >
      <a style={{display: 'flex', justifyContent: 'center', marginBottom: '1rem'}}
        href={`/`}
        className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive
        })}
      >
        <div className={classes.logoImage}>
          <img src={logo} style={{maxWidth: 180}} className={classes.img} />
        </div>
        {logoText}
      </a>
    </div>
  );
  const logOut = (
    <div style={{display: 'flex', justifyContent: 'center', marginBottom: '2rem', height: '4rem'}}><Button onClick={()=>{
      localStorage.setItem("jwt", '');
      window.location.replace(`/`);
    }} color={"warning"} size="sm">{t("Logout")}</Button></div>
  )
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={"left"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {links}
          </div>

          {logOut}
          {/* {image !== undefined ? (
            <div
              className={classes.background}
              // style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null} */}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={"left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {logOut}
          {/* {image !== undefined ? (
            <div
              className={classes.background}
              // style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null} */}
        </Drawer>
      </Hidden>
    </div>
  );
}

export default Sidebar
