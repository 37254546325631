import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardAvatar from "../../components/Card/CardAvatar";
import CardBody from "../../components/Card/CardBody";
import CardFooter from "../../components/Card/CardFooter";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import NavBar from "../../components/Navbars/Navbar";
import {
  useParams,
  useRouteMatch,
  useHistory
} from "react-router-dom";
import { GET_PROJECTS, GET_CUSTOMER_PROJECTS, GET_RELEASES, GET_RELEASE_INFO } from "../../graphql/queries";
import { useQuery, useMutation} from '@apollo/react-hooks';
import { CREATE_OR_UPDATE_ARTIFACT } from "../../graphql/mutations";
import Snackbar from "../../components/Snackbar/Snackbar";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import { setContext } from "apollo-link-context";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import { Artifact } from "../../graphql/graphqlTypes";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Source Sans Pro', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

//@ts-ignore
const useStyles = makeStyles(styles);

const Edit = (props:any) => {
  let { customerid, projectid, buildid } = useParams<any>();
  let history = useHistory();
  const classes = useStyles()
  const { t } = useTranslation();

  const [artifacts, setArtifacts] = useState<any>();
  const [release, setRelease] = useState<any>();
  const { data:releasesData } = useQuery(GET_RELEASES,
    { 
      fetchPolicy: 'network-only', 
      variables: {projectId: parseInt(projectid ? projectid : "")}
    }
  );

  if (releasesData && !release) {
    const r = releasesData.releases.find((release:any) => {
      return buildid == release.build || release.tag.includes(buildid)
    });
    if (r != release) {
      setRelease(r);
    }
  }

  const { data, error, loading } = useQuery(GET_RELEASE_INFO,{ variables: {
    projectId: projectid ? parseInt(projectid) : "",
    build: release ? parseInt(release.build) : -1,
    tag: release ? release.tag : "",
  }, fetchPolicy: 'network-only'});

  if (data && (!artifacts || (artifacts && artifacts.length !== data.releaseInfo.artifacts.length))) {

    setArtifacts(data.releaseInfo.artifacts)

  }
  
  const [ mutate ] = useMutation(CREATE_OR_UPDATE_ARTIFACT);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const getArtifacts = () => {
    return artifacts && artifacts.map((artifact:Artifact) =>
    <Card> 
      <div style={{padding: '0 1rem 0.5rem 1rem'}}>
        <GridContainer>
        <GridItem xs={12} sm={12} md={5}>
          <CustomInput
            info
            labelText={t("Grinder name")}
            id="name"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              disabled: true,
              value: artifact.grinderName
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={5}>
          <CustomInput
            labelText={t("Display name")}
            id="name"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: (event:any) => {
                if (!artifacts) return false;
                let newArtifacts = artifacts.map((a:Artifact) => {
                  if (a.grinderName === artifact.grinderName) {
                    return {...artifact, displayName: event.target.value}
                  }

                  
                  return a;
                })
                setArtifacts(newArtifacts)
              },
              value: artifact.displayName
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
            <div style={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <FormControlLabel checked={artifact.visible} control={<Switch onChange={(event:any)=>{
                if (!artifacts) return false;
                let newArtifacts = artifacts.map((a:Artifact) => {
                  if (a.grinderName === artifact.grinderName) {
                    return {...artifact, visible: !a.visible}
                  }
                  return a;
                })
                setArtifacts(newArtifacts)
            }} color="default"/>} label={t("Visible")} /></div>
        </GridItem>
        </GridContainer>
      </div>
    </Card>
    )
  }

  return (
    <div>
      <Snackbar
        place="tc"
        color="danger"
        icon={ErrorOutline}
        message={t("Failed to update") + " " + t("Release").toLowerCase()}
        open={showSnackbar}
        closeNotification={() => setShowSnackbar(false)}
        close
      />
      <NavBar />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color={"danger"}>
              <h4 className={classes.cardTitleWhite}>{t("Edit")+" "+t("Release").toLowerCase()}</h4>
            </CardHeader>
            <CardBody>
              {getArtifacts()}
              {/* <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <div style={{height: '2rem'}} />
                </GridItem>
              </GridContainer> */}
              {/* <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <FormGroup row>
                    <FormControlLabel checked={build.visib && project.visible ? true : false} control={<Switch onChange={(event:any)=>{
                      // if (!project) return;
                      // setProject({ ...project, visible: !project.visible})
                    }} color="default"/>} label={t("Visible")} />
                  </FormGroup>
                </GridItem>
              </GridContainer> */}
            </CardBody>
            <CardFooter>
              <div>
                <Button onClick={async ()=>{

                  let updatedArtifacts = artifacts.filter((a:Artifact, index:number) => {
                    if (a != data.releaseInfo.artifacts[index]) {
                      return true;
                    }
                    return false;
                  })

                  let failedToSave = false;
                  await Promise.all(updatedArtifacts.map(async (a:Artifact) => {
                    try {
                      let data = await mutate({
                        variables:{
                          projectId: parseInt(projectid ? projectid : ""), 
                          buildId: release ? parseInt(release.build) : -1,
                          grinderName: a.grinderName,
                          displayName: a.displayName,
                          visible: a.visible
                        }
                      })
                      if (!data) {
                        throw "failed to save"
                      }
                    } catch (e) {
                      failedToSave = true;
                      setShowSnackbar(true);
                    }
                  }))
                  if (!failedToSave) {
                    history.goBack();
                  }
                }} color={"danger"}>{t("Update")+" "+t("Project")}</Button>
                <Button onClick={async ()=>{
                    history.goBack();
                  }} color={"transparent"}>{t("Cancel")}</Button>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default Edit