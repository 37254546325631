import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import styles from "../../assets/jss/styles/components/cardHeaderStyle";

//@ts-ignore
const useStyles = makeStyles(styles);

interface CardHeaderProps {
  className?: any,
  color?: 
    "warning" |
    "success" |
    "danger" |
    "info" |
    "primary" |
    "rose"
  plain?: boolean,
  stats?: boolean,
  login?: boolean,
  icon?: boolean,
  children?: any
};


const CardHeader = (props:CardHeaderProps) => {
  const classes = useStyles();
  const { className, children, color, plain, stats, icon, login, ...rest } = props;
  const cardHeaderClasses = classNames({
    [classes.cardHeader]: true,
    [classes[color + "CardHeader"]]: color,
    [classes.cardHeaderPlain]: plain,
    [classes.cardHeaderStats]: stats,
    [classes.cardHeaderIcon]: icon,
    [classes.login]: login,
    [className]: className !== undefined
  });
  return (
    <div className={cardHeaderClasses} {...rest}>
      {children}
    </div>
  );
}

export default CardHeader;