import React, { useState } from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import Done from "@material-ui/icons/Done";
import Archive from "@material-ui/icons/Archive";
import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import Table from "../../components/Table/Table";
import NavBar from "../../components/Navbars/Navbar";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardAvatar from "../../components/Card/CardAvatar";
import CardBody from "../../components/Card/CardBody";
import CardFooter from "../../components/Card/CardFooter";
import Button from "../../components/CustomButtons/Button";

import {
  useParams,
  useRouteMatch,
  useHistory
} from "react-router-dom";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import styles from "../../assets/jss/styles/views/dashboardStyle";

import { useQuery} from '@apollo/react-hooks';
import { GET_PROJECTS, GET_CUSTOMER_PROJECTS, GET_GROUPS, GET_CURRENT_USER } from '../../graphql/queries'
import { useTranslation } from "react-i18next";
import { UserRole } from "../../helpers";

//@ts-ignore
const useStyles = makeStyles(styles);

const Projects = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:1120px)');
  const { data:currentUserData } = useQuery(GET_CURRENT_USER);
  let { customerid } = useParams<any>();
  let history = useHistory();

  let projectTableHeaders = [{key:"avatar", name:""}, {key:"name", name:t("Name")}, {key:"description", name:t("Description")}]
  if (currentUserData && currentUserData.currentUser.role === UserRole.Admin) {
    projectTableHeaders.push({key:"visible", name:t("Visible")})
  }

  const [projects, setProjects] = useState<any>();
  const [customer, setCustomer] = useState<any>();

  const { data:ourProjectsData } = useQuery(GET_PROJECTS, {fetchPolicy: 'network-only'});
  const { data:customersProjectsData } = useQuery(GET_CUSTOMER_PROJECTS,{ variables: {groupId: parseInt(customerid ? customerid : "")}, fetchPolicy: 'network-only'});

  if (!projects) {
    if (customerid && customersProjectsData) {
      setProjects(customersProjectsData.projects);
    } else if (!customerid && ourProjectsData) {
      setProjects(ourProjectsData.projects);
    }
  }
  const { data, error, loading } = useQuery(GET_GROUPS);

  if (!customer && customerid && data) {
    const c = data.groups.filter((group:any) => {
      if (group.id == customerid) {
        return true
      }
      return false;
    })[0];
    setCustomer(c);
  }
  const getCustomerInfo = () => {
    return <GridItem xs={12} sm={12} md={12}>
    <Card>
      <CardBody>
      <div style={{display:'flex', flexDirection: matches ? 'row' : 'column', justifyContent: 'space-between', alignItems: 'center'}}>
        {customer.avatar_url && 
        <a onClick={e => e.preventDefault()}>
          <img src={customer.avatar_url+''} style={{height: 50}} />
        </a>}
        <h4 className={classes.cardTitle}>{customer.name}</h4>
        <p className={classes.description} style={{maxWidth: matches ? '50%' : '100%'}}>
          {customer.description}
        </p>
        <div><Button color="rose" round onClick={()=>{
          history.push({
            pathname: `/customers/${customerid}/edit`
          });
        }}>
          {t("Edit")}
        </Button></div>
        </div>
      </CardBody>
    </Card>
  </GridItem>
  }

  return (
    <div>
      <NavBar />
      {customer && getCustomerInfo()}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTabs
            title={t("Projects")}
            headerColor="info"
            tabs={[
              {
                tabName: t("Active"),
                tabIcon: Done,
                tabContent: (
                  <Table
                    tableHeaders={projectTableHeaders}
                    tableHeaderColor="primary"
                    tableData={projects ? projects.filter((project:any)=>{return !project.archived}): []}
                    editable={true}
                    type={"project"}
                  />
                )
              },
              {
                tabName: t("Archived"),
                tabIcon: Archive,
                disabled: !projects || !projects.filter((project:any)=>{return project.archived}).length,
                tabContent: (
                  <Table
                    tableHeaders={projectTableHeaders}
                    tableHeaderColor="primary"
                    tableData={projects ? projects.filter((project:any)=>{return project.archived}) : []}
                    editable={true}
                    type={"project"}
                  />
                )
              }
            ]}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default Projects
