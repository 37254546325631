import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import GrainIcon from '@material-ui/icons/Grain';
import {
  useParams,
  useRouteMatch,
  useHistory
} from "react-router-dom";
import { useQuery } from '@apollo/react-hooks';
import { GET_GROUPS, GET_PROJECTS, GET_CUSTOMER_PROJECTS, GET_CURRENT_USER } from '../../graphql/queries';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      display: 'flex',
      color: 'grey !important'
    },
    icon: {
      marginRight: theme.spacing(0.5),
      width: 20,
      height: 20,
    },
  }),
);

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
  // event.preventDefault();
  // console.info('You clicked a breadcrumb.');
}

export default function IconBreadcrumbs() {
  const classes = useStyles();
  const { t } = useTranslation();
  let { projectid, customerid, buildid } = useParams<any>();
  const [customer, setCustomer] = useState<any>();
  const { data, error, loading } = useQuery(GET_GROUPS);
  if (!customer && customerid && data) {
    const c = data.groups.filter((group:any) => {
      if (group.id == customerid) {
        return true
      }
      return false;
    })[0];
    setCustomer(c);
  }
  const [project, setProject] = useState<any>();
  const { data:ourProjectsData } = useQuery(GET_PROJECTS);
  const { data:currentUserData } = useQuery(GET_CURRENT_USER);
  const { data:customersProjectsData } = useQuery(GET_CUSTOMER_PROJECTS,{ variables: {groupId: parseInt(customerid ? customerid : "")}});
  if (!project && projectid) {
    if (customerid && customersProjectsData) {
      const p = customersProjectsData.projects.filter((project:any) => {
        if (project.id == projectid) {
          return true
        }
        return false;
      })[0];
      setProject(p)
    } else if (!customerid && ourProjectsData) {
      const p = ourProjectsData.projects.filter((project:any) => {
        if (project.id == projectid) {
          return true
        }
        return false;
      })[0];
      setProject(p)
    }
  }

  const showDashboard = window.location.href.indexOf("dashboard") !== -1;
  const showOurCustomers = window.location.href.indexOf("customers") !== -1;
  const showCustomer = customerid !== undefined;
  const showOurProjects = window.location.href.indexOf("projects") !== -1;
  const showProject = projectid !== undefined;
  const showBuilds = window.location.href.indexOf("releases") !== -1;
  const showBuild = buildid !== undefined;
  const showEdit = window.location.href.indexOf("edit") !== -1;

  return (
    <div style={{marginBottom: 30}}>
    <Breadcrumbs aria-label="breadcrumb">
      <Link color="inherit" href={`/`} onClick={handleClick} className={classes.link}>
        <HomeIcon className={classes.icon} />
        {currentUserData.currentUser.name}
      </Link>

      {showDashboard && <Link
        color="inherit"
        href={`/dashboard`}
        onClick={handleClick}
        className={classes.link}
      >
        {t("Dashboard")}
      </Link>}
      
      {showOurCustomers && <Link
        color="inherit"
        href={`/customers`}
        onClick={handleClick}
        className={classes.link}
      >
        {t("Customers")}
      </Link>}

      {showCustomer && <Link
        color="inherit"
        href={`/customers/${customerid}`}
        onClick={handleClick}
        className={classes.link}
      >
        {customer ? customer.name : ""}
      </Link>}

      {showOurProjects && !customerid && <Link
        color="inherit"
        href={`/projects`}
        onClick={handleClick}
        className={classes.link}
      >
        {t("Projects")}
      </Link>}

      {showProject && <Link
        color="inherit"
        href={customerid ? `/customers/${customerid}/projects/${projectid}` : `/projects/${projectid}`}
        onClick={handleClick}
        className={classes.link}
      >
        {project ? project.name : ""}
      </Link>}

      {showEdit && !customerid && <Link
        color="inherit"
        href={`/projects/${projectid}/edit`}
        onClick={handleClick}
        className={classes.link}
      >
        {t("Edit")}
      </Link>}

      {showEdit && customerid && <Link
        color="inherit"
        href={projectid ? `/customers/${customerid}/projects/${projectid}/edit` : `/customers/${customerid}/edit`}
        onClick={handleClick}
        className={classes.link}
      >
        {t("Edit")}
      </Link>}

      {showBuild && <Link
        color="inherit"
        href={customerid ? `/customers/${customerid}/projects/${projectid}/releases/${buildid}` : 
        `/projects/${projectid}/releases/${buildid}`}
        onClick={handleClick}
        className={classes.link}
      >
        {buildid.replace("ext-","")}
      </Link>}
    </Breadcrumbs>
    </div>
  );
}