import gql from 'graphql-tag';

export const GET_CURRENT_USER = gql`
  query currentUser {
    currentUser {
      id
      username
      role
      groupId
      avatar_url
      name
    }
  }
`;

export const GET_GROUPS = gql`
  query groups {
    groups{
      id,
      name, 
      description, 
      web_url, 
      avatar_url, 
      domain,
      actAs,
      archived
    }
  }
`;

export const GET_PROJECTS = gql`
  query projects {
    projects{
      id,
      name, 
      description, 
      web_url, 
      avatar_url, 
      grinderUrl, 
      archived,
      visible
    }
  }
`;

export const GET_DOWNLOADS = gql`
  query downloads {
    downloads {
      groupId,
      email,
      projectId,
      projectName,
      buildId,
      displayName,
      grinderName,
      date
    }
  }
`;

export const GET_CUSTOMER_PROJECTS = gql`
  query projects($groupId: Int!) {
    projects(groupId: $groupId){
      id,
      name, 
      description, 
      web_url, 
      avatar_url, 
      grinderUrl, 
      archived,
      visible
    }
  }
`;

export const GET_RELEASES = gql`
  query releases($projectId: Int!, $limit: Int) {
    releases(projectId: $projectId, limit: $limit){
      build,
      tag, 
      date, 
    }
  }
`;

export const GET_RELEASE_INFO = gql`
  query releaseInfo($projectId: Int!, $build: Int, $tag:String) {
    releaseInfo(projectId: $projectId, build: $build, tag: $tag){
      tagMessage,
      artifacts {
        grinderName,
        displayName,
        visible
      },
      changeLog,
      assets {
        name,
        path
      },
      documentation {
        name,
        path
      },
      sha
    }
  }
`;