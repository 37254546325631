import React, { useState } from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Apple from "@material-ui/icons/Apple";
import Android from "@material-ui/icons/Android";
import GetApp from "@material-ui/icons/GetApp";
import AttachFile from "@material-ui/icons/AttachFile";
import Description from "@material-ui/icons/Description";

import { deliverables } from "../../variables/general";
// core components
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import Table from "../../components/Table/Table";
import NavBar from "../../components/Navbars/Navbar";
import {
  useParams,
  useRouteMatch,
  useHistory,
  useLocation
} from "react-router-dom";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardIcon from "../../components/Card/CardIcon";
import CardBody from "../../components/Card/CardBody";

import styles from "../../assets/jss/styles/views/dashboardStyle";
import { Typography, Tabs } from "@material-ui/core";
import { GET_RELEASE_INFO, GET_CURRENT_USER, GET_RELEASES } from "../../graphql/queries";
import { useQuery } from "@apollo/react-hooks";
import { UserRole } from "../../helpers";
import { useTranslation } from "react-i18next";
import Snackbar from "../../components/Snackbar/Snackbar";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import { Artifact } from "../../graphql/graphqlTypes";

//@ts-ignore
const useStyles = makeStyles(styles);

const ProjectDetails = () => {
  const { t } = useTranslation();
  let ownProjects = useRouteMatch("/projects");
  let { projectid, buildid } = useParams<any>();
  let history = useHistory();
  const { state } = useLocation<any>();
  let buildName, tagName;
  if (state) {
    buildName = state.buildName;
    tagName = state.tagName
  }
  const classes = useStyles();

  const [build, setBuild] = useState<any>();
  const [release, setRelease] = useState<any>();
  let query = new URLSearchParams(useLocation().search);
  const [showSnackbar, setShowSnackbar] = useState(query.get("error") ? true : false);

  const { data:releasesData } = useQuery(GET_RELEASES,{ variables: {projectId: parseInt(projectid ? projectid : "")}});

  if (releasesData && !release) {
    const r = releasesData.releases.find((release:any) => {
      return buildid == release.build || release.tag.includes(buildid)
    });
    if (r != release) {
      setRelease(r);
    }
  }

  const { data, error, loading } = useQuery(GET_RELEASE_INFO,{ variables: {
    projectId: projectid ? parseInt(projectid) : "",
    build: release ? parseInt(release.build) : -1,
    tag: release ? release.tag : "",
  }, fetchPolicy: 'network-only'});

  const { data:currentUserData } = useQuery(GET_CURRENT_USER);

  if (data && build != data.releaseInfo) {
    setBuild(data.releaseInfo)
  }

  const deliverablesTableHeaders = [{key:"name", name:t("Name")}]

  if (currentUserData.currentUser.role === UserRole.Admin) {
    deliverablesTableHeaders.push({key:"path", name:t("Path")});
  }

  const getArtifactUrl = (grinderName:string, displayName:string) => {
    const jwt = localStorage.getItem('jwt');
    return process.env.NODE_ENV === 'production' ? 
    `https://${process.env.REACT_APP_DOMAIN}/artifact/${grinderName}?build=${release.build}&projectId=${projectid}&displayName=${displayName}&jwt=${jwt}` :
    `http://localhost:3001/artifact/${grinderName}?build=${release.build}&projectId=${projectid}&displayName=${displayName}&jwt=${jwt}`;
  }

  const getIcon = (artifact:string) => {
    if (artifact.endsWith(".apk")) {
      return <Android />
    } else if (artifact.endsWith(".ipa")) {
      return <Apple />
    } else {
      return <GetApp />
    }
  }

  const sortedArtifacts = build ? build.artifacts.sort((a:any, b:any) => {
    if (a && a.grinderName.endsWith(".apk")) {
        return -1;
    }
    if (a && a.grinderName.endsWith(".zip")) {
        return 1;
    }
    return 0;
  }) : []

  const getTabs = () => {
    let tabs = [];
    if (build && build.assets.length) {
      tabs.push({
        tabName: t("Assets"),
        tabIcon: AttachFile,
        tabContent: (
          <Table
            tableHeaders={deliverablesTableHeaders}
            tableHeaderColor="warning"
            tableData={build && build.assets ? build.assets : []}
            editable={false}
            type={"customer"}
          />
        )
      })
    }
    if (build && build.documentation.length) {
      tabs.push({
        tabName: t("Documentation"),
        tabIcon: Description,
        tabContent: (
          <Table
            tableHeaders={deliverablesTableHeaders}
            tableHeaderColor="warning"
            tableData={build && build.documentation ? build.documentation : []}
            editable={false}
            type={"customer"}
          />
        )
      })
    }
    if (!build || !build.assets.length) {
      tabs.push({
        tabName: t("Assets"),
        tabIcon: AttachFile,
        disabled: true,
        tabContent: (
          <Table
            tableHeaders={deliverablesTableHeaders}
            tableHeaderColor="warning"
            tableData={[]}
            editable={false}
            type={"customer"}
          />
        )
      })
    }
    if (!build || !build.documentation.length) {
      tabs.push({
        tabName: t("Documentation"),
        tabIcon: Description,
        disabled: true,
        tabContent: (
          <Table
            tableHeaders={deliverablesTableHeaders}
            tableHeaderColor="warning"
            tableData={[]}
            editable={false}
            type={"customer"}
          />
        )
      })
    }
    return tabs;
  }

  return (
    <div>
      <Snackbar
        place="tc"
        color="danger"
        icon={ErrorOutline}
        message={t("Failed to download file, try again later")}
        open={showSnackbar}
        closeNotification={() => setShowSnackbar(false)}
        close
      />
      <NavBar />
      {/* <Typography variant="h4" component="h4">
        Releases
      </Typography> */}
        <GridContainer>
        {sortedArtifacts.map((artifact:Artifact)=>{
          return <>
            {<a href={getArtifactUrl(artifact.grinderName, artifact.displayName)} style={{marginLeft: '1rem', marginRight:'1rem', width: 300}}>
              <Card>
                <CardHeader color="warning" stats icon>
                  <CardIcon color="warning">
                    {getIcon(artifact.grinderName)}
                  </CardIcon>
                  <p className={classes.cardCategory}>{artifact.displayName}</p>
                </CardHeader>
                <CardBody />
              </Card>
            </a>}
          </>
        })}
        </GridContainer>
        {build && build.tagMessage && <><Typography variant="h4" component="h4">
          {buildid && Number.isInteger(parseInt(buildid)) ? t("Release description") : t("Tag message")}
        </Typography>
        <Card>
          <CardBody>
            <p className={classes.cardCategory}>{build && build.tagMessage ? build.tagMessage : ""}</p>
          </CardBody>
        </Card></>}
        {currentUserData.currentUser.role === UserRole.Admin && (build && build.changeLog.length > 0) && <>
        <Typography variant="h4" component="h4">
          {t("Release notes")}
        </Typography>
        <Card>
          <CardBody>
            {build && build.changeLog.map((change:any)=> {
              return <p className={classes.cardCategory}>{change}</p>
            })}
          </CardBody>
        </Card>
        </>}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTabs
            title={t("Deliverables")}
            headerColor="warning"
            tabs={getTabs()}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default ProjectDetails
