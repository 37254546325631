import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardAvatar from "../../components/Card/CardAvatar";
import CardBody from "../../components/Card/CardBody";
import CardFooter from "../../components/Card/CardFooter";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import {
  useParams,
  useRouteMatch,
  useHistory
} from "react-router-dom";
import NavBar from "../../components/Navbars/Navbar";
import { useQuery, useMutation} from '@apollo/react-hooks';
import { GET_GROUPS } from '../../graphql/queries'
import Snackbar from "../../components/Snackbar/Snackbar";
import { CREATE_OR_UPDATE_GROUP } from "../../graphql/mutations";
import { useTranslation } from "react-i18next";


const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Source Sans Pro', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

//@ts-ignore
const useStyles = makeStyles(styles);

const EditCustomer = (props:any) => {
  const { t } = useTranslation();
  let { customerid } = useParams<any>();
  let history = useHistory();
  const classes = useStyles();
  const [ mutate ] = useMutation(CREATE_OR_UPDATE_GROUP);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const [ customer, setCustomer ] = useState<any>();

  const { data, error, loading } = useQuery(GET_GROUPS, {fetchPolicy: 'network-only'});

  if (!customer && data) {
    const c = data.groups.filter((group:any) => {
      if (group.id == customerid) {
        return true
      }
      return false;
    })[0];
    setCustomer(c);
  }

  return (
    <div>
      <Snackbar
        place="tc"
        color="danger"
        icon={ErrorOutline}
        message={t("Failed to update")+ " " + t("Customer").toLowerCase()}
        open={showSnackbar}
        closeNotification={() => setShowSnackbar(false)}
        close
      />
      <NavBar />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color={"rose"}>
              <h4 className={classes.cardTitleWhite}>{t("Edit")+" "+t("Customer").toLowerCase()}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    success
                    labelText={t("Name")}
                    id="name"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: customer ? customer.name : ""
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    success
                    labelText={t("Description")}
                    id="description"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: customer ? customer.description : ""
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    success
                    labelText="Git url"
                    id="git-url"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: customer ? customer.web_url : ""
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <div style={{height: '2rem'}} />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    success
                    labelText={t("Domain")}
                    id="domain"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: customer? customer.domain : "",
                      onChange: (event:any) => {
                        if (!customer) return false;
                        setCustomer({ ...customer, domain:event.target.value })
                      }
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <FormGroup row>
                <FormControlLabel checked={customer && customer.archived ? true : false} control={<Switch onChange={(event:any)=>{
                    if (!customer) return;
                    setCustomer({ ...customer, archived: !customer.archived})
                  }} color="secondary"/>} label={t("Archived-s")} />
                </FormGroup>
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <div style={{display: 'flex'}}>
                <Button onClick={async ()=>{
                  try {
                    let data = await mutate({variables:{groupId: parseInt(customerid ? customerid : "-1"), domain: customer.domain, archived: customer.archived}})
                    if (data) {
                      history.goBack();
                    } else {
                      throw "failed to save"
                    }
                  } catch (e) {
                    setShowSnackbar(true);
                  }
                }} color={"rose"}>{t("Update")+" "+t("Customer")}</Button>
                <Button onClick={async ()=>{
                  history.goBack();
                }} color={"transparent"}>{t("Cancel")}</Button>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default EditCustomer